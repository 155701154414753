/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable dot-notation */
import { Field } from '@atlaskit/form';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Select, { AsyncSelect } from '@atlaskit/select';
import Toggle from '@atlaskit/toggle';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { IoIosClose, IoIosSearch, IoMdAddCircle } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Cest } from '~/@types/Cest';
import { Fildset } from '~/components/Fildset';
import FormDefault from '~/components/FormDefault';
import { ButtonForm } from '~/components/FormDefault/styles';
import { useQueryClient } from 'react-query';

import {
  InputMaskNcm,
  InputNumber,
  InputPercent,
  InputText,
  InputSelect,
} from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import useDebounce from '~/hooks/useDebounce';
import api from '~/services/api';
import { moneyFormat, transformAsCurrency } from '~/utils/functions';

import { pesoFormatado } from './formatMask';
import {
  CloseButton,
  Container,
  ContainerLoader,
  ContainerRow,
  FildsetContainer,
  GroupItem,
  GroupItens,
  LabelDoFildset,
  SearchNCM,
} from './styles';
import {
  ISelect,
  MapeamentoValores,
  ResponseTipoItem,
  tipoPisCofinsEntrada,
  tipoPisCofinsSaida,
  TipoUf,
  validationInputProps,
} from './types';
import { percentuaisIBPT } from './utils/percentuaisIBPT';
import { schema, ufValidate } from './Validations';
import { nanoid } from 'nanoid';
import { LojaContext } from '~/context/loja';
import ModalSped from './components/ModalSped';
import { InputSped } from './components/SpedInput';
import { Button } from 'react-bootstrap';
import { tipoNaoIncidente } from '../Produto/types';
import { loadingContext } from '~/context/loading';

const tiposNaoIcidente = [
  {
    label: 'Alíquota Zero',
    value: 0,
  },
  {
    label: 'Monofásico',
    value: 1,
  },
  {
    label: 'Substituição',
    value: 2,
  },
  {
    label: 'Imune',
    value: 3,
  },
  {
    label: 'Suspensão',
    value: 4,
  },
];

const MySwal = withReactContent(Swal);

const Ncm: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    unregister,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const {
    register: ufRegister,
    handleSubmit: ufHandleSubmit,
    control: ufControl,
    setValue: setValueUf,
    reset: resetUf,
    clearErrors: clearErrorsUf,
    getValues: getValuesUf,
    formState: formStateUf,
  } = useForm({
    resolver: yupResolver(ufValidate),
    reValidateMode: 'onBlur',
  });
  const { setLoading } = useContext(loadingContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [Init, setInit] = useState<boolean>(false);
  const [InitUF, setInitUF] = useState<boolean>(false);
  const [InitInvalid, setInitInvalid] = useState(false);
  const [initCest, setInitCest] = useState<boolean>(false);
  const [enableModalSped, setEnableModalSped] = useState<boolean>(false);
  const [optionsEntradaPisCofins, setOptionsEntradaPisCofins] = useState<any>(
    [],
  );
  const { colors } = useContext(ThemeContext);
  const { loja } = useContext(LojaContext);
  const queryClient = useQueryClient();
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [idTab, setIdTab] = useState(0);
  // Valores do select de seleção de UF, no modal de adição de us na tipificação
  const [optionsSelectUF, setOptionsSelectUF] = useState<
    Array<{
      label: string;
      value: string;
      tooltipDescription?: string;
      tooltipPosition?: string;
    }>
  >([]);

  // Valores do select de seleção de Tipos Ufs, no modal de adição de us na tipificação
  const [optionsSelectTipos, setOptionsSelectTipos] = useState<
    Array<{
      label: string;
      value: number;
      tooltipDescription?: string;
      tooltipPosition?: string;
    }>
  >([]);
  // Valores do select de seleção de Tributação de entrada, no modal de adição de us na tipificação

  const [optionsSelectTribEntrada, setOptionsTribEntrada] = useState<
    Array<{
      label: string;
      value: number;
      tooltipDescription?: string;
      tooltipPosition?: string;
    }>
  >([]);

  // Valores do select de seleção de Tributação de Saida, no modal de adição de us na tipificação

  const [optionsSelectTribSaida, setOptionsTribSaida] = useState<
    Array<{
      label: string;
      value: number;
      tooltipDescription?: string;
      tooltipPosition?: string;
    }>
  >([]);

  // contorla valores dos inputs
  const [codNcm, setCodNcm] = useState(0);
  const [numNcm, setNumNcm] = useState<string>('');
  const [codCest, setCodCest] = useState<any>(null);
  const [perAliqNac, setPerAliqNac] = useState<number>(0);
  const [perAliqImp, setPerAliqImp] = useState<number>(0);
  const [perAliqEst, setPerAliqEst] = useState<number>(0);
  const [perAliqMun, setPerAliqMun] = useState<number>(0);
  const [valoresAnt, setValoresAnt] = useState<any>();
  // armazenas os tipos
  const [tipoItems, setTipoItems] = useState<
    Array<{
      cod_tipo_item: number;
      num_tipo_item: string;
      des_tipo_item: string;
    }>
  >([]);

  const [tiposUF, setTipoUF] = useState<Array<TipoUf>>([]);

  // controle de modal adicionar uf
  const [isOpenModalAdicionarUf, setIsOpenModalAdicionarUf] = useState(false);
  const closeModalUf = () => {
    setIsOpenModalAdicionarUf(false);
    setValueUf('ufItem', '');
    setValueUf('tribEntradaItem', '');
    setValueUf('tribSaidaItem', '');
    setValueUf('cod_tipo', '');
    setValueUf('iva', '');
    setValueUf('vedCred', '');
    setValueUf('fcpSaida', '');
    setInitUF(false);
  };
  const openModalUf = () => setIsOpenModalAdicionarUf(true);
  const [itemUF, setItemUF] = useState<{
    label: string;
    value: number;
  }>({
    label: '',
    value: 0,
  });

  const [openNcmList, setOpenNcmList] = useState<boolean>(false);
  const [searchNcm, setSearchNcm] = useState<string>('');
  const [isAlterNcmItem, setIsAlterNcmItem] = useState<boolean>(false);
  const [loadingNcmValidation, setLoadingNcmValidation] =
    useState<boolean>(false);
  const { debouncedFn } = useDebounce();
  const [NcmList, setNcmList] = useState<any[]>([]);
  const [inputValidate, setInputValidate] = useState<validationInputProps[]>(
    [],
  );

  useEffect(() => {
    if (numNcm.length > 0) {
      clearErrors('num_ncm');
      clearErrors('num_des_ncm');
    }
  }, [numNcm, openNcmList, clearErrors]);

  useEffect(() => {
    const { errors } = formStateUf;

    if (
      errors?.cod_tipo?.message
        ?.toString()
        .includes('cod_tipo must be a `number` type')
    ) {
      setValueUf('cod_tipo', 0);
      clearErrorsUf('cod_tipo');
    }
  }, [clearErrorsUf, formStateUf, setValueUf]);

  /**
   * Validações
   */
  const handleValidate = useCallback(
    (val, field) => {
      const AllValidations = inputValidate.filter(
        (item) => item.Field !== field,
      );
      if (typeof val === 'string') {
        setInputValidate([
          ...AllValidations,
          { Field: field, Invalid: val.trim() === '' },
        ]);
      }
      if (typeof val === 'number') {
        setInputValidate([
          ...AllValidations,
          { Field: field, Invalid: val < 0 },
        ]);
      }
      if (field === 'cest' && val === 0) {
        setInputValidate([
          ...AllValidations,
          { Field: field, Invalid: val === 0 },
        ]);
      }
    },
    [inputValidate],
  );

  /**
   * Validação do campo CEst
   */
  const handleChangeCest = useCallback(
    (val: any) => {
      setCodCest(val);
      handleValidate(val.value, 'cest');
    },
    [handleValidate, setValue],
  );

  /**
   * Busca informações iniciais da api
   */
  useEffect(() => {
    (async () => {
      const { data } = await api.get('/uf');

      if (data.success) {
        const options = data.data.map(
          ({
            des_sigla,
            cod_uf_ibge,
          }: {
            des_sigla: string;
            cod_uf_ibge: number;
          }) => ({
            label: des_sigla,
            value: cod_uf_ibge,
          }),
        );

        options.sort((a: any, b: any) => a.label.localeCompare(b.label));

        setOptionsSelectUF(options);
      }
    })();
  }, [setValue]);
  /**
   * Resposnavel por formatar os cest buscados no effect acima
   */
  useEffect(() => {
    if (tipoItems.length > 0) {
      const options = tipoItems.map((tipos) => ({
        label: `${tipos.des_tipo_item}`,
        value: tipos.cod_tipo_item,
      }));

      setOptionsSelectTipos(options);
    }
  }, [tipoItems]);

  /**
   * Responsável por buscar os tipos items
   */
  useEffect(() => {
    (async () => {
      const { data } = await api.get<ResponseTipoItem>('/tipo-item');

      if (data.success && data.data) {
        setTipoItems(data.data);
      }
    })();
  }, []);

  /*
   * Responsável por buscar as Tributações de entrada e saida
   */
  useEffect(() => {
    (async () => {
      buscaTributacaoEntrada();
      buscaTributacaoSaida();
      buscaCreditoPresumido();
    })();
  }, []);

  const buscaTributacaoEntrada = async () => {
    const { data } = await api.get('/tributacao?type=entrada');

    if (data.success && data.data) {
      const values = data.data.map((i: any) => ({
        value: i.cod_tributacao,
        label: `${i.cod_tributacao} - ${i.des_tributacao} ${
          i.cod_situacao_tributaria ? ` - ${i.cod_situacao_tributaria}` : ''
        }`,
      }));

      setOptionsTribEntrada(values);
    }
  };
  const buscaTributacaoSaida = async () => {
    const { data } = await api.get('/tributacao?type=saida');

    if (data.success && data.data) {
      const values = data.data.map((i: any) => ({
        value: i.cod_tributacao,
        label: `${i.cod_tributacao} - ${i.val_icms}% - ${i.des_tributacao} ${
          i.cod_situacao_tributaria ? ` - ${i.cod_situacao_tributaria}` : ''
        }`,
      }));

      setOptionsTribSaida(values);
    }
  };

  const buscaCreditoPresumido = async () => {
    const { data } = await api.get('/produto/consulta-credito-presumido');
    if (data.success && data.data) {
      const values = data.data.map((item: any) => ({
        value: item.cod_cred_pres,
        label: item.descricao,
      }));
      const options = [
        {
          value: null,
          label: 'Nenhuma',
        },
        ...values,
      ];
      setOptionsEntradaPisCofins(options);
    }
  };

  function handleNCMValidation(item: any) {
    if (item) {
      setValue('num_ncm', item.num_ncm);
      setValue('num_des_ncm', item.des_ncm);
      setNumNcm(item.num_ncm);
      const allValidates = inputValidate;
      const IndexValidatedNcm = allValidates.findIndex(
        (item) => item.Field === 'num_ncm',
      );
      const IndexValidatedDes = allValidates.findIndex(
        (item) => item.Field === 'descricao',
      );

      if (allValidates[IndexValidatedNcm]?.Invalid) {
        allValidates[IndexValidatedNcm].Invalid = false;
      }
      if (allValidates[IndexValidatedDes]?.Invalid) {
        allValidates[IndexValidatedDes].Invalid = false;
      }
      setInputValidate(allValidates);

      setValue('per_aliq_nac', item.per_aliq_nac);
      setValue('per_aliq_imp', item.per_aliq_imp);
      setValue('per_aliq_mun', item.per_aliq_mun);
      setValue('per_aliq_est', item.per_aliq_est);

      setPerAliqNac(item.per_aliq_nac);
      setPerAliqImp(item.per_aliq_imp);
      setPerAliqMun(item.per_aliq_mun);
      setPerAliqEst(item.per_aliq_est);

      setOpenNcmList(false);
    }
  }
  /**
   * Reposanvel por adicionar um novo tipo UF
   */

  const addTipoUf = (item: TipoUf) => {
    const existsTipo = tiposUF.find(
      (i) => i.cod_tipo_item === item.cod_tipo_item,
    );

    if (existsTipo) return 0;
    const newItens = [...tiposUF, item];
    setValue('tipos', newItens);
    setTipoUF((i) => [...i, item]);
    if (tiposUF.length === 0) {
      setIdTab(item.cod_tipo_item);
    }
  };

  /**
   * Responsavel por remover um tipo UF
   * @param cod
   */
  const removeTipoUf = async (cod: number) => {
    const existTributacoes = tiposUF.find((i) => i.cod_tipo_item === cod);

    // Validação se existem produtos vinculados a essa Tipificação de NCM
    const payload = {
      cod_ncm: codNcm,
      cod_tipo_item: cod,
    };
    try {
      const { data } = await api.get('/validate-tipo-item', {
        params: payload,
      });
      if (data.success && data.data) {
        if (Number(data.data[0].count) > 0) {
          await MySwal.fire({
            html: `
            <p>Existem produtos vinculados à este NCM e com essa Tipificação. Operação não permitida.</p>
            <p>Regularize a situação para prosseguir.</p>
            `,
            icon: 'warning',
          });
          return;
        }
      }
    } catch (error) {
      toast.warn(error);
    }

    if (existTributacoes && existTributacoes?.ufs.length > 0) {
      const { isConfirmed } = await MySwal.fire({
        title: '',

        text: 'Esta Tipificação possui tributações vinculadas. Deseja realmente fechá-la?',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (!isConfirmed) {
        return;
      }
    }

    const newTipos = tiposUF.filter((i) => i.cod_tipo_item !== cod);
    unregister(`per_veda_cred_lim_${cod}`);
    unregister(`tipo_nao_pis_cofins_${cod}`);
    unregister(`des_sped_${cod}`);
    unregister(`cod_sped_${cod}`);
    const removedTipo = tiposUF.find((i) => i.cod_tipo_item === cod);

    /**
     * Flag responsável por fazer a marcação de qual uf
     * será removida na API.
     * O estado tipoUF continua tendo controle pela renderização
     * e o estado o reacthookform fica pelo controle a info
     * que será enviada na API.
     */
    const tipoWithRemovalFlag = {
      ufs: removedTipo?.ufs,
      cod_tipo_item: removedTipo?.cod_tipo_item,
      isRemove: true,
    };
    setValue('tipos', [...newTipos, tipoWithRemovalFlag]);
    setTipoUF(newTipos);
  };

  const handleOpenModalAddUf = (cod_item: number) => {
    const findUf = optionsSelectTipos.find((i) => i.value === cod_item);
    if (findUf) setItemUF(findUf);

    openModalUf();
  };

  const handleAddItemUf = ufHandleSubmit((data) => {
    setInitUF(true);

    const { cod } = getValuesUf();

    if (data.cod_tipo === undefined || data.cod_tipo === null)
      data.cod_tipo = 0;

    const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);

    if (findIndexUf < 0) return;

    const newsTipo = Array.from(tiposUF);

    const item = { ...newsTipo[findIndexUf] };
    const { ufs } = item;

    const existsUf = ufs.find((i) => i.des_sigla === data.ufItem.label);
    if (existsUf) return toast.warning('UF já cadastrada');

    ufs.push({
      index: nanoid(),
      des_sigla: data.ufItem?.label,
      cod_trib_entrada: data.tribEntradaItem.value,
      cod_tributacao: data.tribSaidaItem.value,
      per_fcp_icms: data.fcpSaida,
      per_fcp_st: data.fcpSaida,
      per_iva: data.iva,
      per_veda_cred: data.vedCred,
    });

    setValueUf(`per_iva_${data.ufItem?.label}-${cod}`, data.iva);
    setValueUf(`per_fcp_icms_${data.ufItem?.label}-${cod}`, data.fcpSaida);
    setValueUf(`per_fcp_st_${data.ufItem?.label}-${cod}`, data.fcpSaida);
    setValueUf(`per_veda_cred_${data.ufItem?.label}-${cod}`, data.vedCred);

    item.ufs = ufs;
    newsTipo[findIndexUf] = item;

    setTipoUF(newsTipo);

    setValue('tipos', newsTipo);

    closeModalUf();
  });

  const handleAddItemUfReplica = ufHandleSubmit((data) => {
    setInitUF(true);

    const { cod } = getValuesUf();

    if (data.cod_tipo === undefined || data.cod_tipo === null)
      data.cod_tipo = 0;

    const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);

    if (findIndexUf < 0) return;

    const newsTipo = Array.from(tiposUF);
    const item = { ...newsTipo[findIndexUf] };

    let { ufs } = item;

    ufs = [];

    optionsSelectUF.forEach((i) => {
      ufs.push({
        index: nanoid(),
        des_sigla: i.label,
        cod_trib_entrada: data.tribEntradaItem.value,
        cod_tributacao: data.tribSaidaItem.value,
        per_fcp_icms: data.fcpSaida,
        per_fcp_st: data.fcpSaida,
        per_iva: data.iva,
        per_veda_cred: data.vedCred,
      });

      setValueUf(`per_iva_${i.label}-${cod}`, data.iva);
      setValueUf(`per_fcp_icms_${i.label}-${cod}`, data.fcpSaida);
      setValueUf(`per_fcp_st_${i.label}-${cod}`, data.fcpSaida);
      setValueUf(`per_veda_cred_${i.label}-${cod}`, data.vedCred);
    });

    item.ufs = ufs;
    newsTipo[findIndexUf] = item;

    setValue('tipos', newsTipo);
    setTipoUF(newsTipo);

    closeModalUf();
  });

  useEffect(() => {
    const grid = tiposUF.filter((tp) => tp.cod_tipo_item === idTab);

    if (grid.length <= 0) return;

    const { ufs } = grid[0];

    if (ufs.length <= 0) return;

    ufs.forEach((uf) => {
      const keyPerIva = `per_iva_${uf.des_sigla}-${idTab}`;
      const keyPerFcpIcms = `per_fcp_icms_${uf.des_sigla}-${idTab}`;
      const keyPerFcpSt = `per_fcp_st_${uf.des_sigla}-${idTab}`;
      const keyPerVedaCred = `per_veda_cred_${uf.des_sigla}-${idTab}`;

      const valuePerIva = getValuesUf(keyPerIva);
      const valuePerFcpIcms = getValuesUf(keyPerFcpIcms);
      const valuePerFcpSt = getValuesUf(keyPerFcpSt);
      const valuePerVedaCred = getValuesUf(keyPerVedaCred);

      const inputIva: HTMLInputElement | null = window.document.querySelector(
        `[name="${keyPerIva}"]`,
      );

      const inputFcpIcms: HTMLInputElement | null =
        window.document.querySelector(`[name="${keyPerFcpIcms}"]`);

      const inputFcpSt: HTMLInputElement | null = window.document.querySelector(
        `[name="${keyPerFcpSt}"]`,
      );

      const inputVedaCred: HTMLInputElement | null =
        window.document.querySelector(`[name="${keyPerVedaCred}"]`);

      if (inputIva) inputIva.value = valuePerIva;
      if (inputFcpIcms) inputFcpIcms.value = valuePerFcpIcms;
      if (inputFcpSt) inputFcpSt.value = valuePerFcpSt;
      if (inputVedaCred) inputVedaCred.value = valuePerVedaCred;

      setValueUf(keyPerIva, valuePerIva);
      setValueUf(keyPerFcpIcms, valuePerFcpIcms);
      setValueUf(keyPerFcpSt, valuePerFcpSt);
      setValueUf(keyPerVedaCred, valuePerVedaCred);
    });
  }, [tiposUF, idTab, setValueUf, getValuesUf]);

  async function AlertIncidePISCofins() {
    if (isUpdate) {
      const reponse = await Swal.fire({
        text: 'Todos os produtos amarrados ao NCM terão seus dados de PIS/COFINS e de Crédito Vedado atualizado para os novos valores, deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });
      return reponse.isConfirmed;
    }
    return true;
  }

  /**
   * responsavel pelo flag de Incidente pis cofins daos tipos
   */
  const changeIncidePISCofins = useCallback(
    async (cod: number) => {
      setIsAlterNcmItem(true);
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };
        if (!item.flg_nao_pis_cofins) {
          setValue(`tipo_nao_pis_cofins_${item.cod_tipo_item}`, {
            label: 'Alíquota Zero',
            value: 0,
          });
          handleChangeValoresCst(
            '0' as keyof MapeamentoValores,
            item.cod_tipo_item,
          );
        } else {
          setTimeout(() => {
            setValue(`tipo_nao_pis_cofins_${item.cod_tipo_item}`, {
              value: '',
              label: ' ',
            });
          }, 10);
        }

        item.flg_nao_pis_cofins = !item.flg_nao_pis_cofins;

        newsTipo[findIndexUf] = item;

        setTipoUF(newsTipo);
      }
    },
    [tiposUF],
  );

  /**
   * responsavel pelo select tipo nao incidente dados tipos
   */
  const changeTipoNaoIncidente = useCallback(
    async (cod: number, value: any) => {
      setIsAlterNcmItem(true);
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };

        item.tipo_nao_pis_cofins = value.value;

        newsTipo[findIndexUf] = item;

        setTipoUF(newsTipo);
      }
    },
    [tiposUF],
  );

  /**
   * responsavel pelo flag de Credito vedado daos tipos
   */
  const changeIncideCreditoVedado = useCallback(
    (cod: number) => {
      setIsAlterNcmItem(true);
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };
        if (item.flg_veda_cred) {
          item.per_veda_cred_lim = 0;
        }
        setInitInvalid(false);
        setValue(`per_veda_cred_lim_${cod}`, '');

        item.flg_veda_cred = !item.flg_veda_cred;

        newsTipo[findIndexUf] = item;

        setTipoUF(newsTipo);
      }
    },
    [tiposUF, setValue],
  );

  const changeIncideCreditoVedadoLim = useCallback(
    (cod: number) => {
      setIsAlterNcmItem(true);
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };
        if (item.flg_veda_cred) {
          item.per_veda_cred_lim = 0;
        }
        item.flg_veda_cred = !item.flg_veda_cred;

        setValue('flg_veda_cred', !item.flg_veda_cred);
        newsTipo[findIndexUf] = item;
        setTipoUF(newsTipo);
      }
    },
    [tiposUF, setValue],
  );

  const changeVedaCredLimite = useCallback(
    async (event: any, cod: number) => {
      setIsAlterNcmItem(true);
      setInitInvalid(false);

      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (transformAsCurrency(event) < 100) {
        if (findIndexUf >= 0) {
          const newsTipo = [...tiposUF];

          const item = { ...newsTipo[findIndexUf] };
          item.per_veda_cred_lim = transformAsCurrency(event);
          setValue(`per_veda_cred_lim_${cod}`, event);
          newsTipo[findIndexUf] = item;
          setTipoUF(newsTipo);
        }
      }
      if (event <= 0) {
        setInitInvalid(true);
      } else {
        setInitInvalid(false);
      }
    },
    [tiposUF, setValue],
  );

  /**
   * responsavel pelo flag de Credito vedado daos tipos
   */
  const changeEstornoDeCredito = useCallback(
    (cod: number) => {
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };

        item.flg_est_cred_icms = !item.flg_est_cred_icms;

        newsTipo[findIndexUf] = item;

        setTipoUF(newsTipo);
      }
    },
    [tiposUF],
  );

  const changeEntradaNF = useCallback(
    (cod: number) => {
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };

        item.flg_trib_ent_transf = !item.flg_trib_ent_transf;

        newsTipo[findIndexUf] = item;

        setTipoUF(newsTipo);
      }
    },
    [tiposUF],
  );

  /**
   * Responsável por limpar o formulario
   */
  const resetFormData = () => {
    setOpenNcmList(false);
    setInputValidate([]);
    setUpdate(false);
    setIsAlterNcmItem(false);
    setInit(false);
    setCodNcm(0);
    setCodCest(null);
    reset();
    setNumNcm('');
    setSearchNcm('');

    setValue('per_aliq_nac', 0);
    setValue('per_aliq_imp', 0);
    setValue('per_aliq_mun', 0);
    setValue('per_aliq_est', 0);
    setValue('num_ncm', '');
    setValue('num_des_ncm', '');
    setValue('num_excecao_tipi', undefined);
    setValue('cod_cest', null);

    setPerAliqNac(0);
    setPerAliqImp(0);
    setPerAliqMun(0);
    setPerAliqEst(0);
    setInitInvalid(false);
    resetUf();
    setValoresAnt([]);
    setTipoUF([]);
    setNcmList([]);
  };

  /**
   * elemento responsavel por renderizar e controlar os selcts de sleção de Tributação de entrada da tabela de Tipificação uf
   */
  const ElementIcmsEntradaItem: React.FC<any> = ({ codItem, index, trib }) => {
    const [isOpen, setIsOpen] = useState(false);
    const valueSelected = optionsSelectTribEntrada.find(
      (i) => i.value === trib,
    );

    return (
      <div id="DropDownWidth" title={!isOpen ? valueSelected?.label : ''}>
        <Select
          className="single-select"
          classNamePrefix="react-select"
          options={optionsSelectTribEntrada}
          placeholder="Selecione..."
          value={valueSelected}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          onChange={(value) =>
            changeElementIcmsEntradaItem(codItem, index, value?.value)
          }
        />
      </div>
    );
  };

  const changeElementIcmsEntradaItem = useCallback(
    (cod: number, index: string, val: any) => {
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };

        const { ufs } = item;

        const newUfs = ufs.map((i) => {
          if (i.index === index) {
            const dados = {
              ...i,
              cod_trib_entrada: val,
            };
            return dados;
          }
          return i;
        });

        item.ufs = newUfs;

        newsTipo[findIndexUf] = item;

        setTipoUF(newsTipo);
      }
    },
    [tiposUF],
  );

  /**
   * elemento responsavel por renderizar e controlar os selcts de sleção de Tributação de saida da tabela de Tipificação uf
   */
  const ElementIcmsSaidaItem: React.FC<any> = ({ codItem, index, trib }) => {
    const [isOpen, setIsOpen] = useState(false);

    const valueSelected = optionsSelectTribEntrada.find(
      (i) => i.value === trib,
    );

    return (
      <div id="DropDownWidth" title={!isOpen ? valueSelected?.label : ''}>
        <Select
          className="single-select"
          classNamePrefix="react-select"
          options={optionsSelectTribEntrada}
          placeholder="Selecione..."
          value={valueSelected}
          onMenuOpen={() => setIsOpen(true)}
          onMenuClose={() => setIsOpen(false)}
          onChange={(value) =>
            changeElementIcmsSaidaItem(codItem, index, value?.value)
          }
        />
      </div>
    );
  };

  const changeElementIcmsSaidaItem = useCallback(
    (cod: number, index: string, val: any) => {
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const newsTipo = [...tiposUF];

        const item = { ...newsTipo[findIndexUf] };

        const { ufs } = item;

        const newUfs = ufs.map((i) => {
          if (i.index === index) {
            const dados = {
              ...i,
              cod_tributacao: val,
            };
            return dados;
          }
          return i;
        });

        item.ufs = newUfs;

        newsTipo[findIndexUf] = item;

        setTipoUF(newsTipo);
      }
    },
    [tiposUF],
  );

  /**
   * Função para remover um item da uf
   * @param param
   */
  const handleRemoveItem = useCallback(
    async (cod: number, index: string) => {
      const confirm = await MySwal.fire({
        title: ``,
        text: 'Deseja realmente remover esta tributação?',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        return result.isConfirmed;
      });

      if (!confirm) {
        return;
      }
      const findIndexUf = tiposUF.findIndex((i) => i.cod_tipo_item === cod);
      if (findIndexUf >= 0) {
        const removeUfToPayload = getValues('tipos');
        let ufToRemove = removeUfToPayload[findIndexUf].ufs.find(
          (item: any) => item.index === index,
        );

        /**
         * Flag responsável por fazer a marcação de qual Tipo
         * será removido.
         */

        ufToRemove = {
          ...ufToRemove,
          isRemove: true,
        };

        const newsTipo = [...tiposUF];
        const item = { ...newsTipo[findIndexUf] };

        const { ufs } = item;

        const newUfs = ufs.filter((i) => i.index !== index);

        item.ufs = newUfs;

        newsTipo[findIndexUf] = item;
        removeUfToPayload[findIndexUf].ufs = [...newUfs, ufToRemove];
        setValue('tipos', removeUfToPayload);

        setTipoUF(newsTipo);
      }
    },
    [tiposUF],
  );

  const InputCEST: React.FC<any> = ({ onChange, value }) => {
    const [inputText, setInputText] = useState<string>('');

    const promiseOptions = async (inputValue: string) => {
      let options = [];
      if (inputText.length >= 3) {
        const { data } = await api.get('/busca-cest', {
          params: {
            search: inputValue,
          },
        });

        if (data.success && data.data) {
          options = data.data.map((cest: Cest) => ({
            label: `${cest.num_cest} - IVA: ${parseFloat(cest.per_iva)}% - ${
              cest.des_cest
            }`,
            value: cest.cod_cest,
            tooltipDescription: cest.des_cest,
            tooltipPosition: 'right',
          }));
        }
        options.unshift({
          label: 'Nenhum',
          value: null,
          tooltipDescription: '',
          tooltipPosition: 'right',
        });
      }

      return options;
    };

    return (
      <div
        title={value?.label}
        style={{
          borderBottom: errors.cod_cest && initCest ? '2px solid #dc3545' : '',
        }}
      >
        <Field name="cest" defaultValue="" label="CEST">
          {() => (
            <AsyncSelect
              className="single-select"
              classNamePrefix="react-select"
              loadOptions={promiseOptions}
              placeholder="Informe o CEST"
              value={value}
              onInputChange={(value: string) => setInputText(value)}
              noOptionsMessage={() =>
                inputText.length <= 3
                  ? 'Informe pelo menos 3 caracteres'
                  : 'Nenhum registro encontrado'
              }
              loadingMessage={() =>
                inputText.length <= 3
                  ? 'Informe pelo menos 3 caracteres'
                  : 'Selecione...'
              }
              onChange={(value: { label: string; value: string }) => {
                onChange(value);
                setInitCest(false);
                setValue('cod_cest', {
                  label: value.label,
                  value: value.value,
                });
              }}
            />
          )}
        </Field>
      </div>
    );
  };

  /**
   * Resposnavel por validar e executar uma ação do item clicado na tablea de pesquisa
   * @param param
   */
  const onRowClick = async (param: GridRowParams) => {
    setShowSearch(false);
    setInit(false);
    setLoader(true);
    setUpdate(true);
    buscaTributacaoEntrada();
    buscaTributacaoSaida();
    const { row } = param;
    setCodNcm(row.cod_ncm);
    setValue('num_ncm', row.num_ncm.toString().padStart(8, '0'));
    setValue('num_des_ncm', row.des_ncm);
    setValue('num_excecao_tipi', row.num_excecao_tipi);
    setNumNcm(row.num_ncm.toString().padStart(8, '0'));

    // Adicionando validação caso CEST seja nulo
    if (row.cod_cest) {
      const cod_cest = await api.get(`/cest/${row.cod_cest}`);
      setValue('cod_cest', {
        label: `${cod_cest.data.data.num_cest} - IVA: ${parseFloat(
          cod_cest.data.data.per_iva,
        )}% - ${cod_cest.data.data.des_cest}`,
        value: cod_cest.data.data.cod_cest,
      });
      setCodCest({
        label: `${cod_cest.data.data.num_cest} - IVA: ${parseFloat(
          cod_cest.data.data.per_iva,
        )}% - ${cod_cest.data.data.des_cest}`,
        value: cod_cest.data.data.cod_cest,
        tooltipDescription: cod_cest.data.data.des_cest,
        tooltipPosition: 'right',
      });
    } else {
      setValue('cod_cest', {
        label: 'Nenhum',
        value: null,
      });
      setCodCest({
        label: 'Nenhum',
        value: null,
      });
    }

    const ncmV = await api.get('/buscar-catalogo-ncm', {
      params: { search: null, num_ncm: row.num_ncm },
    });

    setNcmList(ncmV.data.data || []);

    setValue('per_aliq_nac', row.per_aliq_nac);
    setValue('per_aliq_imp', row.per_aliq_imp);
    setValue('per_aliq_mun', row.per_aliq_mun);
    setValue('per_aliq_est', row.per_aliq_est);

    setPerAliqNac(row.per_aliq_nac);
    setPerAliqImp(row.per_aliq_imp);
    setPerAliqMun(row.per_aliq_mun);
    setPerAliqEst(row.per_aliq_est);

    const { data } = await api.get(`/ncm/${row.cod_ncm}`);
    if (data.success) {
      const { tipos } = data.data;
      const newTiposUf = tipos.map((i: any) => {
        const tt = tipoItems.find((t) => t.cod_tipo_item === i.cod_tipo_item);
        if (tt) {
          handleChangeValoresCst(
            i.tipo_nao_pis_cofins as keyof MapeamentoValores,
            i.cod_tipo_item,
          );
          const findTipoPisCofins = tipoNaoIncidente.find(
            (item) => item.value === i.tipo_nao_pis_cofins,
          );
          const entradaPisCofins = optionsEntradaPisCofins.find(
            (item: any) => item.value === i.cod_cred_pres,
          );
          setTimeout(() => {
            setValue(
              `tipo_nao_pis_cofins_${i.cod_tipo_item}`,
              findTipoPisCofins,
            );
            setValue(`des_sped_${i.cod_tipo_item}`, i.des_tab_sped);
            setValue(`cod_sped_${i.cod_tipo_item}`, i.cod_tab_sped);
            setValue(`entrada_pis_cofins_${i.cod_tipo_item}`, entradaPisCofins);
            setValue(
              `flg_nao_pis_cofins_${i.cod_tipo_item}`,
              i.flg_nao_pis_cofins,
            );
            setValue(
              `per_veda_cred_lim_${i.cod_tipo_item}`,
              moneyFormat(i.per_veda_cred_lim),
            );
          }, 50);
          i.des_tipo_item = tt.des_tipo_item;
        }
        const newUf = i.ufs;
        for (let u = 0; u < newUf.length; u += 1) {
          setValueUf(
            `per_iva_${newUf[u].des_sigla}-${i.cod_tipo_item}`,
            newUf[u].per_fcp_icms !== null ? moneyFormat(newUf[u].per_iva) : 0,
          );
          setValueUf(
            `per_fcp_icms_${newUf[u].des_sigla}-${i.cod_tipo_item}`,
            newUf[u].per_fcp_icms !== null
              ? moneyFormat(newUf[u].per_fcp_icms)
              : 0,
          );
          setValueUf(
            `per_fcp_st_${newUf[u].des_sigla}-${i.cod_tipo_item}`,
            newUf[u].per_fcp_st !== null ? moneyFormat(newUf[u].per_fcp_st) : 0,
          );
          setValueUf(
            `per_veda_cred_${newUf[u].des_sigla}-${i.cod_tipo_item}`,
            newUf[u].per_veda_cred !== null
              ? moneyFormat(newUf[u].per_veda_cred)
              : 0,
          );
          newUf[u].per_iva = moneyFormat(newUf[u].per_iva);
          newUf[u].index = (Math.random() * 100).toString();
        }

        i.ufs = newUf;

        return i;
      });
      setValoresAnt(newTiposUf);
      setValue('tipos', newTiposUf);
      setTipoUF(newTiposUf);
      setIdTab(newTiposUf[0]?.cod_tipo_item);
    }

    setUpdate(true);
    setLoader(false);
  };

  /**
   * Limpa o formulario e retorna para a pesquisa
   */
  const onCancel = () => {
    resetFormData();
    setShowSearch(true);
  };

  /**
   * Reponsavel por excluir um NCM
   * @returns
   */
  const onDelete = async () => {
    try {
      setLoader(true);

      const { data } = await api.delete(`/ncm/${codNcm}`);

      if (data.success) {
        resetFormData();
        setShowSearch(true);
        toast.success(data.message);
      }
    } catch (e: any) {
      if (e.status === 422) {
        toast.warn(e.data);
      } else {
        toast.error(e.data);
      }
    } finally {
      setLoader(false);
    }
  };

  /**
   * Reseta formulario para inserção deum novo registro
   */
  const onNew = () => {
    resetFormData();
    setValue('cod_cest', null);
    setValue('num_excecao_tipi', null);
    setUpdate(false);
  };

  const tiposUfs = useCallback(async (): Promise<TipoUf[] | undefined> => {
    const tipo_uf = getValues('tipos');

    if (tiposUF.length === 0) {
      toast.warning('Deve ter ao menos uma tipificação informada');
      return;
    }
    //  eslint-disable-next-line no-restricted-syntax
    for (const item of tiposUF) {
      if (item.flg_nao_pis_cofins && item.tipo_nao_pis_cofins === null) {
        return;
      }

      if (item.flg_veda_cred === true) {
        const perVenda = getValues(`per_veda_cred_lim_${item.cod_tipo_item}`);
        if (Number(perVenda) > 0) {
          setInitInvalid(false);
        } else if (Number(perVenda) <= 0) {
          setInitInvalid(true);
          return;
        }
      }
      if (item.ufs.length === 0) {
        setLoader(false);
        setIdTab(item.cod_tipo_item);
        toast.warning('Tipificação deve ter ao menos uma UF informada');
        return;
      }
    }

    tipo_uf.map((tipoUf: any, index1: any) => {
      if (tipoUf.isRemove) return false;
      tipoUf.ufs.map((uf: any, index2: any) => {
        const perFcpIcms = getValuesUf(
          `per_fcp_icms_${uf.des_sigla}-${tipoUf.cod_tipo_item}`,
        );
        const perFcpSt = getValuesUf(
          `per_fcp_st_${uf.des_sigla}-${tipoUf.cod_tipo_item}`,
        );
        const perIva = getValuesUf(
          `per_iva_${uf.des_sigla}-${tipoUf.cod_tipo_item}`,
        );
        const perVedaCred = getValuesUf(
          `per_veda_cred_${uf.des_sigla}-${tipoUf.cod_tipo_item}`,
        );

        const tipoNaoPisCofins = getValues(
          `tipo_nao_pis_cofins_${tipoUf.cod_tipo_item}`,
        )?.value;
        const desSped = getValues(`des_sped_${tipoUf.cod_tipo_item}`);
        const codSped = getValues(`cod_sped_${tipoUf.cod_tipo_item}`);
        const entradaPisCofins = getValues(
          `entrada_pis_cofins_${tipoUf.cod_tipo_item}`,
        )?.value;
        const flgNaoPisCofins = getValues(
          `flg_nao_pis_cofins_${tipoUf.cod_tipo_item}`,
        );

        const flgVedaCred = tiposUF[index1].flg_veda_cred;
        const perVedaCredLim = flgVedaCred
          ? transformAsCurrency(
              getValues(`per_veda_cred_lim_${tipoUf.cod_tipo_item}`),
            )
          : null;

        tipo_uf[index1].ufs[index2] = {
          ...tipo_uf[index1].ufs[index2],
          per_fcp_icms: perFcpIcms,
          per_fcp_st: perFcpSt,
          per_iva: perIva,
          per_veda_cred: perVedaCred,
        };

        tipo_uf[index1] = {
          ...tipo_uf[index1],
          tipo_nao_pis_cofins: tipoNaoPisCofins,
          des_sped: desSped,
          cod_sped: codSped,
          entrada_pis_cofins: entradaPisCofins || null,
          flg_nao_pis_cofins: flgNaoPisCofins,
          flg_veda_cred: flgVedaCred,
          per_veda_cred_lim: perVedaCredLim,
        };

        return false;
      });
      return false;
    });

    return tipo_uf;
  }, [AlertIncidePISCofins, getValues, getValuesUf, isAlterNcmItem]);

  /**
   *
   * @description Retorna um JSON sem chaves duplicadas que já
   * foram inseridas no array tipos do objeto, porém continuaram
   * no corpo principal do JSON.
   */
  const cleanJson = (json: any) => {
    const cleanedJson: any = {};

    Object.keys(json).forEach((key) => {
      if (!key.match(/_\d+$/)) {
        cleanedJson[key] = json[key];
      }
    });

    if (json.tipos) {
      cleanedJson.tipos = json.tipos;
    }
    cleanedJson.num_ncm = cleanedJson.num_ncm.replace(/\./g, '');
    return cleanedJson;
  };

  /**
   * @description Verifica a mudança dos valores vindo do banco
   * com os valores que serão enviados a API para atualização.
   *
   * @param payload Mande sempre o mesmo objeto que será enviado a api.
   *
   * @note Usar apenas no método update.
   *
   * @return Retorna true ou false.
   */
  const verificarAlteracoes = (payload: any): boolean => {
    const houveAlteracao = payload.tipos.map((tipoArray: any) => {
      const tipoData = valoresAnt.find(
        (tipo: any) =>
          tipo.cod_tipo_item === tipoArray.cod_tipo_item && !tipoArray.isRemove,
      );

      if (!tipoData) {
        return false;
      }
      tipoData.per_veda_cred_lim = tipoData.per_veda_cred_lim
        ? tipoData.per_veda_cred_lim
        : null;
      tipoData.tipo_nao_pis_cofins =
        tipoData.tipo_nao_pis_cofins !== -1
          ? tipoData.tipo_nao_pis_cofins
          : undefined;
      return (
        tipoData.flg_nao_pis_cofins !== tipoArray.flg_nao_pis_cofins ||
        tipoData.tipo_nao_pis_cofins !== tipoArray.tipo_nao_pis_cofins ||
        tipoData.des_tab_sped !== tipoArray.des_sped ||
        tipoData.cod_tab_sped !== tipoArray.cod_sped ||
        tipoData.flg_veda_cred !== tipoArray.flg_veda_cred ||
        tipoData.cod_cred_pres !== tipoArray.entrada_pis_cofins ||
        tipoData.per_veda_cred_lim !== tipoArray.per_veda_cred_lim
      );
    });
    return houveAlteracao.some((alteracao: any) => alteracao);
  };

  /**
   * Metodo para salvar um novo registro
   * @return
   */
  const onSave = handleSubmit(async (dataInputs) => {
    const tiposUf = await tiposUfs();
    if (!tiposUf) return;
    dataInputs.tipos = tiposUf;
    const payload = cleanJson(dataInputs);
    const houveAlteracao = verificarAlteracoes(payload);

    if (isUpdate && houveAlteracao) {
      setLoading(false);
      const response = await AlertIncidePISCofins();
      if (!response) return;
    }

    setInit(true);

    setSearchNcm('');
    setNcmList([]);
    /*
    @todo verificar content body que está sendo enviado para a api.
    */
    try {
      setLoader(true);
      if (isUpdate) {
        const { data } = await api.put(`/ncm/${codNcm}`, payload);
        if (data.success) {
          toast.success(data.message);

          resetFormData();
          setShowSearch(true);
          if (houveAlteracao) {
            const codTela = queryClient.getQueriesData(['cache_tela_28']);
            codTela.length > 0
              ? queryClient.setQueryData(`ncm_update`, codNcm)
              : queryClient.removeQueries(`ncm_update`);
          }
        } else {
          setInputValidate([{ Field: 'num_ncm', Invalid: true }]);
          toast.warning(data.message);
        }
      } else {
        const { data } = await api.post('/ncm', payload);

        if (data.success) {
          toast.success(data.message);

          resetFormData();
          setShowSearch(false);
        } else {
          setInputValidate([{ Field: 'num_ncm', Invalid: true }]);
          toast.warning(data.message);
        }
      }
    } catch (error: any) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  });
  // filtrar NCMS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function filterNcmValidation(search: string) {
    if (search.length >= 3) {
      setLoadingNcmValidation(true);
      const { data } = await api.get('/buscar-catalogo-ncm', {
        params: { search, num_ncm: null },
      });

      if (data.success && data.data) {
        const ncmData = data.data;
        const sortedNcmData = [...ncmData];
        setNcmList(
          sortedNcmData.sort((a, b) => a.des_ncm.localeCompare(b.des_ncm)),
        );
        setInputValidate([]);
      }
      setLoadingNcmValidation(false);
    }
  }
  const handleChangeSearchNcm = useCallback(
    (value) => {
      const sanitizedValue = value.includes('.')
        ? value.replace(/\./g, '')
        : value;

      setSearchNcm(value);
      debouncedFn(() => filterNcmValidation(sanitizedValue), 700);
    },
    [debouncedFn, filterNcmValidation],
  );

  const onSaveDuplicate = handleSubmit(async (data1) => {
    setInitCest(true);
    setLoader(true);
    setInit(true);

    setSearchNcm('');
    setNcmList([]);

    try {
      const tiposuf = await tiposUfs();

      if (!tiposuf) return;

      data1.tipos = tiposuf;
      data1.num_ncm = data1.num_ncm.replace(/\./g, '');
      if (isUpdate) {
        const { data } = await api.put(`/ncm/${codNcm}`, data1);
        if (data.success) {
          toast.success(data.message);

          setUpdate(false);
          setValue('num_ncm', '');
          setValue('num_des_ncm', '');
          setNumNcm('');
          setPerAliqNac(0);
          setPerAliqImp(0);
          setPerAliqEst(0);
          setPerAliqMun(0);
        } else {
          setInputValidate([{ Field: 'num_ncm', Invalid: true }]);
          toast.warning(data.message);
        }
      } else {
        const { data } = await api.post('/ncm', data1);

        if (data.success) {
          toast.success(data.message);

          setUpdate(false);
          setValue('num_ncm', '');
          setValue('num_des_ncm', '');
          setNumNcm('');
          setPerAliqNac(0);
          setPerAliqImp(0);
          setPerAliqEst(0);
          setPerAliqMun(0);
        } else {
          setInputValidate([{ Field: 'num_ncm', Invalid: true }]);
          toast.warning(data.message);
        }
      }
    } catch (error: any) {
      setLoader(false);
      toast.error(error);
    }
    setLoader(false);
  });

  const desabilitarBuscaModal = (item: any) => {
    if (!item.flg_nao_pis_cofins) {
      return true;
    }
    return false;
  };

  const handleChangeEnabledModal = () => {
    setEnableModalSped((prev: boolean) => {
      return !prev;
    });
  };

  const handleChangeEnabledModalWithButton = () => {
    setEnableModalSped((prev: boolean) => {
      return !prev;
    });
  };

  const handleChangeInput = (
    valores: { entrada: string; saida: string },
    cod_tipo_item: number,
  ) => {
    const entradaFiltrada = tipoPisCofinsEntrada.find((item: ISelect) => {
      return Number(item.value) === Number(valores.entrada);
    });
    const saidaFiltrada = tipoPisCofinsSaida.find((item: ISelect) => {
      return Number(item.value) === Number(valores.saida);
    });
    setValue(`cst_entrada_${cod_tipo_item}`, entradaFiltrada);
    setValue(`cst_saida_${cod_tipo_item}`, saidaFiltrada);
  };

  const mapeamentoValoresCst: MapeamentoValores = {
    '-1': {
      entrada: loja.cst_pis_cof_entrada,
      saida: loja.cst_pis_cof_saida,
    },
    '0': {
      entrada: loja.cst_pis_cof_ent_aliq_zero,
      saida: loja.cst_pis_cof_saida_aliq_zero,
    },
    '1': {
      entrada: loja.cst_pis_cof_ent_monof,
      saida: loja.cst_pis_cof_saida_monof,
    },
    '2': {
      entrada: loja.cst_pis_cof_ent_subst,
      saida: loja.cst_pis_cof_saida_subst,
    },
    '3': {
      entrada: loja.cst_pis_cof_ent_imune,
      saida: loja.cst_pis_cof_saida_imune,
    },
    '4': {
      entrada: loja.cst_pis_cof_ent_susp,
      saida: loja.cst_pis_cof_saida_susp,
    },
    '5': {
      entrada: loja.cst_pis_cof_ent_monof,
      saida: loja.cst_pis_cof_saida,
    },
  };

  const handleChangeValoresCst = (
    tipo: keyof MapeamentoValores,
    cod_tipo_item: number,
  ): { entrada: string; saida: string } | null => {
    if (tipo in mapeamentoValoresCst) {
      const valores = mapeamentoValoresCst[tipo];
      handleChangeInput(valores, cod_tipo_item);
    }
    return null;
  };

  if (loader) {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => {
            resetFormData();
            setShowSearch(false);
          }}
          onRowClick={onRowClick}
          codTela={19}
        />
      )}

      {!showSearch && (
        <FormDefault
          title="Cadastro de NCM"
          codTela={19}
          onCancel={onCancel}
          onClearFields={resetFormData}
          onDelete={onDelete}
          onNew={onNew}
          onReturnSearch={onCancel}
          codigoRegistro={[
            {
              value: codNcm,
              des_campo: 'Código',
            },
          ]}
          onSave={async () => {
            await onSave();
            setInitCest(true);
          }}
          isUpdate={isUpdate}
          isDuplicate
          onDuplicate={async () => {
            await onSaveDuplicate();
          }}
          labelButtonTrash="Inativar"
          deleteMessage="Deseja realmente inativar o NCM?"
        >
          <div className="row my-1">
            <div className="col-sm-12 col-md-3">
              <InputMaskNcm
                label="NCM"
                name="num_ncm"
                hasButton
                control={control}
                register={register}
                clickedButton={() => setOpenNcmList(true)}
                isError={!!errors.num_ncm}
              />
            </div>
            <div className="col-sm-12 col-md-7">
              <InputText
                label="Descrição"
                name="num_des_ncm"
                placeholder="Informe a Descrição"
                register={register}
                control={control}
                maxLength={500}
                caseInput="upper"
                toLowerCase={false}
                isError={!!errors.num_des_ncm}
              />
            </div>
            <div
              title="Tabela de Impostos para Produtos Industrializado"
              className="col-sm-12 col-md-2"
            >
              <InputNumber
                label="Exc. TIPI"
                name="num_excecao_tipi"
                placeholder="TIPI"
                maxLength={999}
                min={0}
                max={999}
                control={control}
                register={register}
                isError={!!errors.num_excecao_tipi}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (String(e.target.value).length <= 3)
                    setValue(
                      'num_excecao_tipi',
                      Math.abs(Number(e.target.value)),
                    );
                }}
              />
            </div>
            <div className="col-sm-12 col-md-4 m-auto">
              <InputCEST
                onChange={handleChangeCest}
                // setInvalid={
                //   inputValidate.find((item) => item.Field === 'cest')?.Invalid
                // }
                Init={Init}
                value={codCest}
              />
            </div>
            <div className="col-sm-12 col-md-8">
              <Fildset label="Percentuais IBPT">
                <div className="row">
                  <LabelDoFildset>
                    <span>
                      Aliq. Nac.:
                      <div>{percentuaisIBPT(perAliqNac)}</div>
                    </span>
                  </LabelDoFildset>
                  <LabelDoFildset>
                    <span>
                      Aliq. Imp.:
                      <div>{percentuaisIBPT(perAliqImp)}</div>
                    </span>
                  </LabelDoFildset>
                  <LabelDoFildset>
                    <span>
                      Aliq. Est.:
                      <div>{percentuaisIBPT(perAliqEst)}</div>
                    </span>
                  </LabelDoFildset>
                  <LabelDoFildset>
                    <span>
                      Aliq. Mun.:
                      <div>{percentuaisIBPT(perAliqMun)}</div>
                    </span>
                  </LabelDoFildset>
                </div>
              </Fildset>
            </div>
            <Separator labelText="Tipificação por UF" />
            <section>
              <ul className="nav nav-tabs" id="myTabUf" role="tablist">
                {tiposUF
                  .map((item) => (
                    <li
                      className="nav-item"
                      role="presentation"
                      key={item.cod_tipo_item}
                    >
                      <a
                        className={`nav-link ${
                          item.cod_tipo_item === idTab ? 'active' : ''
                        }`}
                        onClick={() => {
                          setIdTab(item.cod_tipo_item);
                        }}
                        id={`tab${item.cod_tipo_item}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#tabUf${item.cod_tipo_item}`}
                        type="button"
                        role="tab"
                        aria-controls={`tabUf${item.cod_tipo_item}`}
                        aria-selected={
                          item.cod_tipo_item === idTab ? 'true' : 'false'
                        }
                      >
                        {`${item.cod_tipo_item.toString().padStart(2, '0')}.${
                          item.des_tipo_item
                        }`}
                        <button
                          type="button"
                          onClick={() => {
                            removeTipoUf(item.cod_tipo_item);
                          }}
                          className="close-tipo"
                        >
                          <MdClose />
                        </button>
                      </a>
                    </li>
                  ))
                  .sort((a, b) => {
                    return Number(a.key) - Number(b.key);
                  })}

                <li className="nav-item dropend" role="presentation">
                  <a
                    className="nav-link dropdown-toggle"
                    id="dropMenuTipo"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <IoMdAddCircle size={20} color={colors.success} />
                  </a>

                  <ul className="dropdown-menu" aria-labelledby="dropMenuTipo">
                    {tipoItems.map((item) => (
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => {
                            setTimeout(() => {
                              setValue(
                                `entrada_pis_cofins_${item.cod_tipo_item}`,
                                {
                                  label: 'Nenhuma',
                                  value: null,
                                },
                              );
                            }, 100);
                            addTipoUf({
                              ...item,
                              flg_nao_pis_cofins: false,
                              tipo_nao_pis_cofins: 0,
                              flg_est_cred_icms: false,
                              flg_veda_cred: false,
                              per_veda_cred_lim: 0,
                              flg_trib_ent_transf: false,
                              ufs: [],
                            });
                            const hasTabExists = tiposUF.some(
                              (i) => i.cod_tipo_item === item.cod_tipo_item,
                            );
                            if (hasTabExists) setIdTab(item.cod_tipo_item);
                          }}
                        >
                          {item.num_tipo_item}: {item.des_tipo_item}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
              <div className="tab-content" id="tabUFContent">
                {tiposUF.map((item) => (
                  <div
                    key={item.cod_tipo_item}
                    className={
                      item.cod_tipo_item === idTab
                        ? 'tab-pane fade show active'
                        : 'tab-pane fade'
                    }
                    id={`tabUf${item.cod_tipo_item}`}
                    role="tabpanel"
                    aria-labelledby={`tab${item.cod_tipo_item}`}
                  >
                    <Separator labelText="Aplicar nos Itens do NCM" />
                    <FildsetContainer>
                      <Fildset label="PIS/COFINS">
                        <>
                          <GroupItem>
                            <label
                              htmlFor={`toggle-controlled-${item.num_tipo_item}`}
                            >
                              Não Incide PIS-Cofins
                            </label>
                            <Toggle
                              id={`toggle-controlled-${item.num_tipo_item}`}
                              onChange={() => {
                                setValue(
                                  `tipo_nao_pis_cofins_${item.cod_tipo_item}`,
                                  '',
                                );
                                setValue(
                                  `flg_nao_pis_cofins_${item.cod_tipo_item}`,
                                  !item.flg_nao_pis_cofins,
                                );
                                changeIncidePISCofins(item.cod_tipo_item);
                                setValue(
                                  `des_sped_${item.cod_tipo_item}`,
                                  null,
                                );
                                setValue(
                                  `cod_sped_${item.cod_tipo_item}`,
                                  null,
                                );
                              }}
                              isChecked={item.flg_nao_pis_cofins}
                            />
                            <div style={{ height: '100px' }}>
                              <InputSelect
                                label="Tipo Não Incidente"
                                name={`tipo_nao_pis_cofins_${item.cod_tipo_item}`}
                                register={register}
                                isError={!!errors.tipo_nao_pis_cofins}
                                control={control}
                                options={tiposNaoIcidente}
                                isDisabled={!item.flg_nao_pis_cofins}
                                changeSelected={(selected) => {
                                  const tipoPisCofinsAnt = getValues(
                                    `tipo_nao_pis_cofins_${item.cod_tipo_item}`,
                                  );
                                  if (
                                    tipoPisCofinsAnt &&
                                    tipoPisCofinsAnt.value !== selected.value
                                  ) {
                                    setValue(
                                      `tipo_nao_pis_cofins_${item.cod_tipo_item}`,
                                      selected,
                                    );
                                    setValue(
                                      `des_sped_${item.cod_tipo_item}`,
                                      null,
                                    );
                                    setValue(
                                      `cod_sped_${item.cod_tipo_item}`,
                                      null,
                                    );
                                    handleChangeValoresCst(
                                      selected.value as keyof MapeamentoValores,
                                      item.cod_tipo_item,
                                    );
                                    changeTipoNaoIncidente(
                                      item.cod_tipo_item,
                                      selected,
                                    );
                                  }
                                }}
                              />
                            </div>
                          </GroupItem>
                        </>
                      </Fildset>
                      <Fildset label="Crédito Vedado">
                        <>
                          <GroupItens>
                            <label
                              htmlFor={`toggle-controlled2-${item.flg_veda_cred}`}
                            >
                              É Vedado?
                            </label>
                            <Toggle
                              id={`toggle-controlled2-${item.flg_veda_cred}`}
                              onChange={() => {
                                changeIncideCreditoVedado(item.cod_tipo_item);
                                changeIncideCreditoVedadoLim(
                                  item.cod_tipo_item,
                                );
                              }}
                              isChecked={item.flg_veda_cred}
                            />
                            <div style={{ height: '100px' }}>
                              <InputPercent
                                label="Até o Limite de"
                                placeholder="0,00"
                                min={0}
                                max={100}
                                step="2"
                                isDisabled={!item.flg_veda_cred}
                                name={`per_veda_cred_lim_${item.cod_tipo_item}`}
                                register={register}
                                control={control}
                                onInput={(event: any) => {
                                  changeVedaCredLimite(
                                    pesoFormatado(event.target.value, 2),
                                    item.cod_tipo_item,
                                  );
                                }}
                                isError={
                                  !!errors.per_veda_cred_lim || InitInvalid
                                }
                              />
                            </div>
                          </GroupItens>
                        </>
                      </Fildset>
                    </FildsetContainer>
                    <br />

                    <div className="row">
                      <div className="row">
                        <div className="col-md-5 col-lg-5 col-sm-12">
                          <div className="input-container">
                            <InputSped
                              register={register}
                              control={control}
                              item={item.cod_tipo_item}
                            />

                            <Button
                              type="button"
                              className="min-button"
                              disabled={desabilitarBuscaModal(item)}
                              onClick={handleChangeEnabledModalWithButton}
                            >
                              <AiOutlineSearch color="#fff" />
                            </Button>
                          </div>
                        </div>
                        <ModalSped
                          enabledModalSped={enableModalSped}
                          handleChangeEnabledModal={handleChangeEnabledModal}
                          getValues={getValues}
                          setValue={setValue}
                          register={register}
                          setChangeNcmItem={setIsAlterNcmItem}
                          item={idTab}
                        />
                        <div className="col-md-4 col-lg-4 col-sm-12">
                          <InputSelect
                            label="Aliq. Diferenciada (Entrada)"
                            name={`entrada_pis_cofins_${item.cod_tipo_item}`}
                            register={register}
                            options={optionsEntradaPisCofins}
                            isError={false}
                            control={control}
                            changeSelected={(selected) => {
                              setValue(
                                `entrada_pis_cofins_${item.cod_tipo_item}`,
                                selected,
                              );
                            }}
                          />
                        </div>
                        <div className="col d-flex justify-content-end align-center my-4">
                          <ButtonForm
                            className="novo"
                            type="button"
                            onClick={() =>
                              handleOpenModalAddUf(item.cod_tipo_item)
                            }
                          >
                            Adicionar UF
                          </ButtonForm>
                        </div>
                      </div>
                    </div>
                    <Paper style={{ width: '100%' }}>
                      <TableContainer
                        style={{
                          maxHeight: 440,
                          minHeight: 300,
                        }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow className="ok">
                              <TableCell>UF</TableCell>
                              <TableCell style={{ width: '15.6563rem' }}>
                                ICMS Entrada
                              </TableCell>
                              <TableCell style={{ width: '15.6563rem' }}>
                                ICMS Saída (Interno)
                              </TableCell>
                              <TableCell>% IVA</TableCell>
                              <TableCell
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                % FCP Entrada
                              </TableCell>
                              <TableCell
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                % FCP Saída
                              </TableCell>
                              <TableCell
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                % Veda. Créd. Ben.
                              </TableCell>
                              <TableCell>Ações</TableCell>
                            </TableRow>
                          </TableHead>
                          {item.ufs.length > 0 && (
                            <TableBody>
                              {item.ufs.map((uf) => (
                                <TableRow key={uf.index} className="Table-row">
                                  <TableCell>
                                    <div
                                      style={{
                                        marginTop: '10px',
                                      }}
                                    >
                                      {uf.des_sigla}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div>
                                      <ElementIcmsEntradaItem
                                        codItem={item.cod_tipo_item}
                                        trib={uf.cod_trib_entrada}
                                        index={uf.index}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <ElementIcmsSaidaItem
                                      codItem={item.cod_tipo_item}
                                      trib={uf.cod_tributacao}
                                      index={uf.index}
                                    />
                                  </TableCell>
                                  <TableCell className="containerInputPercente">
                                    <InputPercent
                                      label=""
                                      placeholder="0,00"
                                      min={0}
                                      max={999}
                                      showIcon={false}
                                      name={`per_iva_${uf.des_sigla}-${idTab}`}
                                      register={ufRegister}
                                      control={ufControl}
                                      isError={
                                        !!formStateUf.errors[
                                          `per_iva_${uf.des_sigla}-${idTab}`
                                        ]
                                      }
                                    />
                                  </TableCell>
                                  <TableCell className="containerInputPercente">
                                    <InputPercent
                                      label=""
                                      placeholder="0,00"
                                      min={0}
                                      max={999}
                                      showIcon={false}
                                      name={`per_fcp_icms_${uf.des_sigla}-${idTab}`}
                                      register={ufRegister}
                                      control={ufControl}
                                      isError={false}
                                    />
                                  </TableCell>
                                  <TableCell className="containerInputPercente">
                                    <InputPercent
                                      label=""
                                      placeholder="0,00"
                                      min={0}
                                      max={999}
                                      showIcon={false}
                                      name={`per_fcp_st_${uf.des_sigla}-${idTab}`}
                                      register={ufRegister}
                                      control={ufControl}
                                      isError={false}
                                    />
                                  </TableCell>
                                  <TableCell className="containerInputPercente">
                                    <InputPercent
                                      label=""
                                      placeholder="0,00"
                                      min={0}
                                      max={999}
                                      showIcon={false}
                                      name={`per_veda_cred_${uf.des_sigla}-${idTab}`}
                                      register={ufRegister}
                                      control={ufControl}
                                      isError={false}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() =>
                                        handleRemoveItem(
                                          item.cod_tipo_item,
                                          uf.index,
                                        )
                                      }
                                    >
                                      <FaTrash />
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          )}
                        </Table>
                        {item.ufs.length < 1 && (
                          <ContainerRow>
                            <span>Nenhum registro adicionado</span>
                          </ContainerRow>
                        )}
                      </TableContainer>
                    </Paper>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </FormDefault>
      )}

      <ModalTransition>
        {openNcmList && (
          <Modal
            onClose={() => {
              setOpenNcmList(false);
              setSearchNcm('');
              setNcmList([]);
            }}
            width="x-large"
          >
            <ModalHeader
              style={{
                paddingBottom: '0.5rem',
              }}
            >
              <ModalTitle>Selecione o NCM</ModalTitle>
              <CloseButton
                onClick={() => {
                  setOpenNcmList(false);
                  setSearchNcm('');
                  setNcmList([]);
                }}
              >
                <AiOutlineClose size={24} />
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              <div className="row p-2 d-flex content-around gap-4">
                <div className="col-12 col-md-12">
                  <SearchNCM>
                    <IoIosSearch focusable={false} aria-hidden="true" />
                    <input
                      type="text"
                      spellCheck="false"
                      placeholder="Pesquisar"
                      autoComplete="off"
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      value={searchNcm}
                      onChange={(e) => {
                        handleChangeSearchNcm(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setSearchNcm('');
                        setNcmList([]);
                      }}
                    >
                      <IoIosClose style={{ cursor: 'pointer' }} />
                    </button>
                  </SearchNCM>
                </div>
                <div className="col-12 col-md-12">
                  {loadingNcmValidation ? (
                    <ContainerLoader style={{ height: '250px' }}>
                      <ClipLoader color={colors.primary} />
                    </ContainerLoader>
                  ) : (
                    <TableContainer style={{ height: '250px' }}>
                      <Table size="small">
                        <TableHead
                          style={{ position: 'sticky', top: 0, zIndex: 1 }}
                        >
                          <TableRow>
                            <TableCell style={{ width: '50px' }}>NCM</TableCell>
                            <TableCell style={{ maxWidth: '600px' }}>
                              Descrição
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {NcmList.map((item) => {
                            return (
                              <TableRow
                                key={item.num_ncm}
                                title="Clique sobre o NCM para selecioná-lo"
                                style={{
                                  background:
                                    item.num_ncm === numNcm
                                      ? '#85edad'
                                      : 'none',
                                }}
                                onClick={() => handleNCMValidation(item)}
                              >
                                <TableCell>{item.num_ncm}</TableCell>
                                <TableCell>{item.des_ncm}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {NcmList.length < 1 && (
                        <>
                          {searchNcm.length < 4 ? (
                            <ContainerRow>
                              <span>
                                Informe pelo menos 3 caracteres para pesquisar
                              </span>
                            </ContainerRow>
                          ) : (
                            <ContainerRow>
                              <span>Nenhum registro encontrado</span>
                            </ContainerRow>
                          )}
                        </>
                      )}
                    </TableContainer>
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </ModalTransition>

      <ModalTransition>
        {isOpenModalAdicionarUf && (
          <Modal onClose={closeModalUf} width="medium">
            <ModalHeader
              style={{
                paddingTop: '0.7rem',
              }}
            >
              <ModalTitle>Tributação por Federação</ModalTitle>
              <CloseButton onClick={closeModalUf}>
                <AiOutlineClose size={24} />
              </CloseButton>
            </ModalHeader>
            <ModalBody style={{ paddingBottom: '1rem', marginTop: '-10px' }}>
              <div className="row p-2">
                <div className="col-12 col-md-4">
                  <InputSelect
                    label="UF"
                    name="ufItem"
                    placeholder="Selecione..."
                    control={ufControl}
                    register={ufRegister}
                    options={optionsSelectUF}
                    changeSelected={(newValue: any) => {
                      setValueUf('ufItem', newValue);
                      clearErrorsUf('ufItem');
                    }}
                    isError={!!formStateUf.errors.ufItem}
                  />
                </div>

                <div className="col-12 col-md-8">
                  <Field label="Tipo de Item" name="tipo_item">
                    {() => (
                      <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        options={optionsSelectTipos}
                        placeholder="Selecione..."
                        value={itemUF}
                        isDisabled
                      />
                    )}
                  </Field>
                </div>

                <div className="col-12 col-md-12">
                  <InputSelect
                    label="Entrada"
                    name="tribEntradaItem"
                    placeholder="Selecione..."
                    register={ufRegister}
                    control={ufControl}
                    options={optionsSelectTribEntrada}
                    changeSelected={(newValue: any) => {
                      setValueUf('tribEntradaItem', newValue);
                      clearErrorsUf('tribEntradaItem');
                    }}
                    isError={!!formStateUf.errors.tribEntradaItem}
                  />
                </div>

                <div className="col-12 col-md-12">
                  <InputSelect
                    label="Saída"
                    name="tribSaidaItem"
                    placeholder="Selecione..."
                    control={ufControl}
                    register={ufRegister}
                    options={optionsSelectTribSaida}
                    changeSelected={(newValue: any) => {
                      setValueUf('tribSaidaItem', newValue);
                      clearErrorsUf('tribSaidaItem');
                    }}
                    isError={!!formStateUf.errors.tribSaidaItem}
                  />
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <InputPercent
                    label="IVA"
                    placeholder="0,00"
                    min={0}
                    max={999}
                    name="iva"
                    register={ufRegister}
                    control={ufControl}
                    isError={!!formStateUf.errors.iva}
                  />
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <InputNumber
                    label="Código Tipo"
                    name="cod_tipo"
                    placeholder="0"
                    maxLength={999}
                    max={999}
                    control={ufControl}
                    register={ufRegister}
                    isError={!!formStateUf.errors.cod_tipo}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setValueUf('cod_tipo', Math.abs(Number(e.target.value)));
                    }}
                  />
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <InputPercent
                    label="Veda. Créd. Ben."
                    placeholder="0,00"
                    min={0}
                    max={999}
                    name="vedCred"
                    register={ufRegister}
                    control={ufControl}
                    isError={!!formStateUf.errors.vedCred}
                  />
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <InputPercent
                    label="FCP Saída"
                    placeholder="0,00"
                    min={0}
                    max={999}
                    name="fcpSaida"
                    register={ufRegister}
                    control={ufControl}
                    isError={!!formStateUf.errors.fcpSaida}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter style={{ marginBottom: '0.2rem' }}>
              <div className="d-flex justify-content-between aliggn-center w-full mt-2 ">
                <ButtonForm className="delete" onClick={closeModalUf}>
                  Cancelar
                </ButtonForm>

                <ButtonForm
                  className="limpar"
                  onClick={async () => {
                    setValueUf('cod', itemUF.value);
                    await handleAddItemUfReplica();
                  }}
                  // eslint-disable-next-line react/jsx-no-comment-textnodes
                >
                  Adicionar e replicar <br /> a todas UFs
                </ButtonForm>

                <ButtonForm
                  onClick={async () => {
                    setValueUf('cod', itemUF.value);
                    await handleAddItemUf();
                  }}
                >
                  Adicionar
                </ButtonForm>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </Container>
  );
};

export default Ncm;
